import { buildPrices, LABELS } from './stadiumUtils.js';

const prices = buildPrices([559, 605, 755], [0, 1, 2]);
const label = `${window.punti_cardinali[LABELS.nord]} 1`;
export const NORD_1 = {
    106: { label, prices },
    107: { label, prices },
    108: { label, prices },
    109: { label, prices },
    // 110: { label, prices },
};

const prices2 = buildPrices([587, 638, 796], [0, 1, 2]);
export const NORD_1_FASE_2 = {
    106: { label, prices: prices2 },
    107: { label, prices: prices2 },
    108: { label, prices: prices2 },
    109: { label, prices: prices2 },
    // 110: { label, prices: prices2 },
};
