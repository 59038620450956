/**
 * @param {HTMLElement} element
 * @return {{
 *      navigation: import('swiper/types/modules/navigation.js').NavigationOptions,
 *      pagination: import('swiper/types/modules/pagination.js').PaginationOptions
 * }}
 */
export const swiperControls = (element) => ({
    pagination: {
        el: element.querySelector('.swiper-pagination'),
        type: 'progressbar',
    },
    navigation: {
        prevEl: element.querySelector('.left button'),
        nextEl: element.querySelector('.right button'),
    },
});
