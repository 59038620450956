/**
 * @param {HTMLElement} tabsParent
 * @param {import("swiper/types/swiper-class.js").default} swiper
 * @param {string} [tabSelector]
 * @param {(index: number) => void} onTabClicked
 * @constructor
 */
export const SwiperTabs = ({
    tabsParent,
    swiper,
    tabSelector = '.js-tab',
    onTabClicked,
}) => {
    const tabs = [...tabsParent.querySelectorAll('.js-tab')];
    const changeItem = (index) => {
        tabsParent.querySelector('.js-tab.active').classList.remove('active');
        tabs[index].classList.add('active');
        swiper.slideTo(index);
        onTabClicked(index);
    };

    if (window.innerWidth < 768) {
        onTabClicked(0);
    }

    swiper.on('slideChange', () => {
        changeItem(swiper.activeIndex);
    });

    tabs.forEach((tab, index) => {
        tab.addEventListener('click', () => {
            changeItem(index);
        });
    });
};
