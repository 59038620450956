import { buildPrices, LABELS, NULL_PRICE } from './stadiumUtils.js';

const label = `${window.punti_cardinali[LABELS.ovest]} 2`;
export const OVEST_2 = {
    // 229: { label, prices: buildPrices([1, '2', '3']) },
    // 230: { label, prices: buildPrices([1, '2', '3']) },
    // 232: { label, prices: buildPrices([1, '2', '3']) },
    231: { label, prices: buildPrices(['', 690, '']) },
    // 201: { label, prices: buildPrices([1, '2', '3']) },
    // 202: { label, prices: buildPrices([1, '2', '3']) },
    // 203: { label, prices: buildPrices([1, '2', '3']) },
    // 204: { label, prices: buildPrices([1, '2', '3']) },
    // 205: { label, prices: buildPrices([1, '2', '3']) },
};
export const OVEST_2_FASE_2 = {
    // 229: { label, prices: buildPrices([1, '2', '3']) },
    // 230: { label, prices: buildPrices([1, '2', '3']) },
    // 232: { label, prices: buildPrices([1, '2', '3']) },
    231: { label, prices: buildPrices(['', 690, '']) },
    // 201: { label, prices: buildPrices([1, '2', '3']) },
    // 202: { label, prices: buildPrices([1, '2', '3']) },
    // 203: { label, prices: buildPrices([1, '2', '3']) },
    // 204: { label, prices: buildPrices([1, '2', '3']) },
    // 205: { label, prices: buildPrices([1, '2', '3']) },
};
