import { Component } from '@/scripts/utils/component.js';

/**
 * @param {number} value
 * @return {string}
 */
const addLeadingZero = (value) => (value < 10 ? `0${value}` : value.toString());

/**
 * @param {number} distance
 * @return {string[]}
 */
const getCountdown = (distance) => {
    if (distance < 0) {
        return ['00', '00', '00', '00'];
    }
    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;

    const days = Math.floor(distance / day);
    const hours = Math.floor((distance % day) / hour);
    const minutes = Math.floor((distance % hour) / minute);
    const seconds = Math.floor((distance % minute) / second);

    return [
        addLeadingZero(days),
        addLeadingZero(hours),
        addLeadingZero(minutes),
        addLeadingZero(seconds),
    ];
};

/**
 * @param {HTMLElement} element
 */
const animateCountDown = (element) => {
    /** @type {HTMLElement[]} */
    const countDown = [...element.querySelectorAll('.o-number')];

    /** @type {number} */
    const numberHeight = countDown[0]
        .querySelector('.inner > div')
        .getBoundingClientRect().height;

    const targetDate = new Date(2024, 4, 21, 15, 0, 0);

    const now = new Date();

    const diff = targetDate - now;

    const count = getCountdown(diff);

    countDown.forEach((c, index) => {
        const decine = c.querySelector('.decine .inner');
        const unita = c.querySelector('.unita .inner');
        const targetPart = count[index];

        decine.style.transform = `translateY(${
            (5 - Number.parseInt(targetPart[0], 10)) * numberHeight -
            numberHeight * 5
        }px)`;

        unita.style.transform = `translateY(${
            (9 - Number.parseInt(targetPart[1], 10)) * numberHeight -
            numberHeight * 9
        }px)`;
    });
};

export const Countdown = Component('.js-countdown', (element) => {
    animateCountDown(element);
    setInterval(() => {
        animateCountDown(element);
    }, 1000);
});
