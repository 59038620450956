import { scrollTo } from '@/scripts/utils/scrollTo.js';

export const setUpAnchors = () => {
    [...document.querySelectorAll('[data-anchor]')].forEach((anchor) => {
        anchor.addEventListener('click', (event) => {
            event.preventDefault();
            scrollTo(anchor.dataset.anchor);
            return false;
        });
    });
};
