import { buildPrices, LABELS } from './stadiumUtils.js';

// const prices = buildPrices([559, 605, 755]);
const prices = buildPrices([], [0, 1, 2]);
const label = `${window.punti_cardinali[LABELS.sud]} 1`;
export const VISITORS = {
    // 118: { label, prices },
    // 119: { label, prices },
};

const prices2 = buildPrices([], [0, 1, 2]);
export const VISITORS_FASE_2 = {
    // 118: { label, prices: prices2 },
    // 119: { label, prices: prices2 },
};
