import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import { Component } from '@/scripts/utils/component.js';
import { swiperControls } from '@/scripts/utils/swiper-controls.js';

export const JuventusCard = Component('.js-juve-card', (element) => {
    const video = element.querySelector('.js-video');
    const source = video.querySelector('source');

    const mobileVideoUrl = video.dataset.vMob;
    const deskVideoUrl = video.dataset.vDesk;

    const checkSourceMediaQuery = () => {
        if (window.innerWidth <= 768 && !source.src.includes(mobileVideoUrl)) {
            source.src = mobileVideoUrl;
            video.load();
        } else if (
            window.innerWidth > 768 &&
            !source.src.includes(deskVideoUrl)
        ) {
            source.src = deskVideoUrl;
            video.load();
        }
    };
    checkSourceMediaQuery();
    window.addEventListener('resize', checkSourceMediaQuery);

    new Swiper(element.querySelector('.swiper'), {
        modules: [Pagination, Navigation],
        slidesPerView: 'auto',
        spaceBetween: 16,
        breakpoints: {
            768: {
                slidesPerView: 3,
            },
        },
        ...swiperControls(element),
    });

    const reveal = () => {
        if (
            document.scrollingElement.scrollTop > element.offsetTop - 400 &&
            video.paused
        ) {
            element.classList.add('reveal');
            video.play();
        }
    };
    reveal();
    window.addEventListener('scroll', reveal);
});
