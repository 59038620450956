import { buildPrices, LABELS } from './stadiumUtils.js';

const label = `${window.punti_cardinali[LABELS.est]} 1`;
export const EST_1 = {
    // 110: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    // 111: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    // 112: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    // 113: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    // 114: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    // 115: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    116: {
        label,
        prices: buildPrices(['', 539, '']),
    },
    117: {
        label,
        prices: buildPrices(['', 539, '']),
    },
};

export const EST_1_FASE_2 = {
    // 110: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    // 111: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    // 112: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    // 113: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    // 114: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    // 115: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    116: {
        label,
        prices: buildPrices(['', 539, '']),
    },
    117: {
        label,
        prices: buildPrices(['', 539, '']),
    },
};
