import { scrollTo } from '@/scripts/utils/scrollTo.js';

export const checkLandingAnchor = () => {
    if (location.hash === '') return;
    const target = document.querySelector(location.hash);
    if (target) {
        scrollTo(target);
    } else if (location.hash === '#faq_dazn') {
        const tabDazn = document.querySelector(
            '.js-faq .head-item[data-faq-group="5"]'
        );
        tabDazn.click();
        scrollTo('#faq');
    }
};
