import Swiper from 'swiper';
import { Component } from '@/scripts/utils/component.js';
import { SwiperTabs } from '@/scripts/utils/tabs.js';

export const Rates = Component('.js-rates', (element) => {
    const swiper = new Swiper(element.querySelector('.swiper'), {
        slidesPerView: 1,
        spaceBetween: 16,
        breakpoints: {
            768: {
                slidesPerView: 2,
            },
        },
    });

    SwiperTabs({
        tabsParent: element,
        swiper,
        onTabClicked: (index) => {
            const activeElement = element.querySelector(
                '.swiper-slide .js-box-rate.reveal'
            );
            if (activeElement) {
                activeElement.classList.remove('reveal');
            }
            element
                .querySelectorAll('.swiper-slide')
                [index].querySelector('.js-box-rate')
                .classList.add('reveal');
        },
    });
});
