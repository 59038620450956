import { buildPrices, LABELS } from './stadiumUtils.js';

const label = `${window.punti_cardinali[LABELS.est]} 2`;
export const EST_2 = {
    // 212: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    // 213: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    // 214: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    215: {
        label,
        prices: buildPrices(['', 509, '']),
    },
    // 216: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    // 217: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    // 218: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    // 219: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    // 220: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    221: {
        label,
        prices: buildPrices(['285', '419', '']),
    },
};

export const EST_2_FASE_2 = {
    // 212: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    // 213: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    // 214: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    215: {
        label,
        prices: buildPrices(['', 509, '']),
    },
    // 216: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    // 217: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    // 218: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    // 219: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    // 220: {
    //     label,
    //     prices: buildPrices(['1', '2', '3']),
    // },
    221: {
        label,
        prices: buildPrices(['285', '419', '']),
    },
};
