import { NORD_1, NORD_1_FASE_2 } from './stadium/NORD_1.js';
import { EST_1, EST_1_FASE_2 } from './stadium/EST_1.js';
import { NORD_2, NORD_2_FASE_2 } from './stadium/NORD_2.js';
import { EST_2, EST_2_FASE_2 } from './stadium/EST_2.js';
import { SUD_1, SUD_1_FASE_2 } from '@/scripts/utils/stadium/SUD_1.js';
import { SUD_2, SUD_2_FASE_2 } from '@/scripts/utils/stadium/SUD_2.js';
import { OVEST_1, OVEST_1_FASE_2 } from '@/scripts/utils/stadium/OVEST_1.js';
import { OVEST_2, OVEST_2_FASE_2 } from '@/scripts/utils/stadium/OVEST_2.js';
import { VISITORS } from './stadium/VISITORS.js';

export const stadiumPrices = {
    NORD: {
        ...NORD_1,
        ...NORD_2,
    },
    NORD_FASE_2: {
        ...NORD_1_FASE_2,
        ...NORD_2_FASE_2,
    },
    EST: {
        ...EST_1,
        ...EST_2,
    },
    EST_FASE_2: {
        ...EST_1_FASE_2,
        ...EST_2_FASE_2,
    },
    SUD: {
        ...SUD_1,
        ...SUD_2,
    },
    SUD_FASE_2: {
        ...SUD_1_FASE_2,
        ...SUD_2_FASE_2,
    },
    OVEST: {
        ...OVEST_1,
        ...OVEST_2,
    },
    OVEST_FASE_2: {
        ...OVEST_1_FASE_2,
        ...OVEST_2_FASE_2,
    },
    VISITORS: {
        ...VISITORS,
    },
};
