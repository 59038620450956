import { buildPrices, LABELS } from './stadiumUtils.js';

// const prices = buildPrices([559, 605, 755]);
const prices = buildPrices([559, 605, 755], [0, 1, 2]);
const label = `${window.punti_cardinali[LABELS.sud]} 1`;
export const SUD_1 = {
    118: { label, prices },
    119: { label, prices },
    120: { label, prices },
    121: { label, prices },
    122: { label, prices },
};

const prices2 = buildPrices([587, 638, 796], [0, 1, 2]);
export const SUD_1_FASE_2 = {
    118: { label, prices: prices2 },
    119: { label, prices: prices2 },
    120: { label, prices: prices2 },
    121: { label, prices: prices2 },
    122: { label, prices: prices2 },
};
