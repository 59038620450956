import { Hero } from '@/scripts/components/Hero.js';
import { htmlVariables } from '@/scripts/utils/htmlVariables.js';
import { Phases } from '@/scripts/components/Phases.js';
import { Countdown } from '@/scripts/components/Countdown.js';
import { Stadium } from '@/scripts/components/Stadium.js';
import { setUpAnchors } from '@/scripts/utils/anchors.js';
import { Faq } from '@/scripts/components/Faq.js';
import { Rates } from '@/scripts/components/Rates.js';
import { JuventusCard } from '@/scripts/components/JuventusCard.js';
import { loadJuveHeader } from '@/scripts/utils/loadJuveHeader.js';
import { loadJuveFooter } from '@/scripts/utils/loadJuveFooter.js';
import { Modal } from '@/scripts/components/Modal.js';
import { Products } from '@/scripts/components/Products.js';
import { initModals } from '@/scripts/utils/initModals.js';
import { checkLandingAnchor } from '@/scripts/utils/checkLandingAnchor.js';
import { ProductsV2 } from './components/ProductsV2';

htmlVariables();
Hero();
Countdown();
Phases();
Stadium();
Faq();
Rates();
Products();
ProductsV2();
JuventusCard();
Modal();
setUpAnchors();
loadJuveHeader();
loadJuveFooter();
initModals();
checkLandingAnchor();
