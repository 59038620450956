import Swiper from 'swiper';
import { Scrollbar } from 'swiper/modules';

export const ProductsV2 = () => {
    const root = document.querySelector('.js-productv2-slider');
    const scrollbar = root.querySelector('.swiper-scrollbar');

    // eslint-disable-next-line no-new
    new Swiper(root, {
        modules: [Scrollbar],
        slidesPerView: 3,
        breakpoints: {
            768: {
                slidesPerView: 8,
            },
        },
        scrollbar: {
            el: scrollbar,
            draggable: false,
        },
    });
};
