import Swiper from 'swiper';
import { FreeMode, Navigation, Pagination } from 'swiper/modules';
import { Component } from '@/scripts/utils/component.js';
import { swiperControls } from '@/scripts/utils/swiper-controls.js';

export const Phases = Component('.js-phases', (element) => {
    new Swiper(element.querySelector('.swiper'), {
        modules: [FreeMode, Navigation, Pagination],
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        freeMode: false,
        breakpoints: {
            768: {
                freeMode: true,
                slidesPerGroup: 2,
            },
        },
        ...swiperControls(element),
    });
});
