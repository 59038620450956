import Swiper from 'swiper';
import { Component } from '@/scripts/utils/component.js';
import { SwiperTabs } from '@/scripts/utils/tabs.js';

export const Products = Component('.js-products', (element) => {
    const swiper = new Swiper(element.querySelector('.swiper'), {
        slidesPerView: 1,
        spaceBetween: 12,
        breakpoints: {
            768: {
                slidesPerView: 3,
            },
        },
    });

    SwiperTabs({
        tabsParent: element,
        swiper,
        onTabClicked: () => {
        },
    });
});
