import { buildPrices, LABELS } from './stadiumUtils.js';

const label = `${window.punti_cardinali[LABELS.nord]} 2`;
// const prices = buildPrices([1, 2, 2]);
export const NORD_2 = {
    // 206: { label, prices },
    207: { label, prices: buildPrices(['', 285, '']) },
    208: { label, prices: buildPrices(['', 285, '']) },
    209: { label, prices: buildPrices(['', 285, '']) },
    210: { label, prices: buildPrices(['', 285, '']) },
    // 110: { label, prices },
};

// const prices2 = buildPrices([1, 2, 3]);
export const NORD_2_FASE_2 = {
    // 206: { label, prices: prices2 },
    207: { label, prices: buildPrices(['', 285, '']) },
    208: { label, prices: buildPrices(['', 285, '']) },
    209: { label, prices: buildPrices(['', 285, '']) },
    210: { label, prices: buildPrices(['', 285, '']) },
    // 110: { label, prices: prices2 },
};
