import gsap from 'gsap';

/**
 * @param {string} id
 */
export const scrollTo = (id) => {
    const header = document.querySelector('header');
    gsap.to(window, {
        duration: 2,
        scrollTo: {
            y: id,
            offsetY: header
                ? header.getBoundingClientRect().height
                : window.innerWidth >= 1024
                ? 80
                : 60,
        },
        ease: 'power2.inOut',
    });
};
