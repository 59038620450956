/**
 * @description
 * - The order of the prices is: BASE FULL STAR UNDER14 UNDER28 RIDOTTI
 * - To sold out one of them, pass an array with the sold out indexes
 * @param {string[]|number[]|{price: number | string, discounted: number | string}[]} prices
 * @param {number[]} soldOutIndexes
 */
export const buildPrices = (prices, soldOutIndexes = []) => {
    const labels = [
        { order: 2, label: 'BASE' },
        { order: 1, label: 'FULL' },
        { order: 0, label: 'STAR' },
        { order: 3, label: 'UNDER 14' },
        { order: 4, label: 'UNDER 28' },
        { order: 5, label: 'RIDOTTI' },
    ];

    return prices
        .map((price, index) => ({
            product: labels[index].label,
            order: labels[index].order,
            price: (price && price.price) ?? price,
            discounted: (price && price.discounted) ?? null,
            soldOut: soldOutIndexes.includes(index),
        }))
        .sort((a, b) => a.order - b.order);
};

export const LABELS = { nord: 'NORD', est: 'EST', ovest: 'OVEST', sud: 'SUD' };
export const NULL_PRICE = buildPrices(['-', '-', '-', '-', '-', '-']);
