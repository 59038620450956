import { buildPrices, LABELS } from './stadiumUtils.js';

// const prices = buildPrices([1, 2, 3]);
const label = `${window.punti_cardinali[LABELS.sud]} 2`;
export const SUD_2 = {
    // 222: { label, prices },
    223: { label, prices: buildPrices(['', 285, '']) },
    224: { label, prices: buildPrices(['', 285, '']) },
    225: { label, prices: buildPrices(['', 285, '']) },
    226: { label, prices: buildPrices(['', 285, '']) },
    // 227: { label, prices },
    // 228: { label, prices },
};

// const prices2 = buildPrices([1, 2, 3]);
export const SUD_2_FASE_2 = {
    // 222: { label, prices },
    223: { label, prices: buildPrices(['', 285, '']) },
    224: { label, prices: buildPrices(['', 285, '']) },
    225: { label, prices: buildPrices(['', 285, '']) },
    226: { label, prices: buildPrices(['', 285, '']) },
    // 227: { label, prices: prices2 },
    // 228: { label, prices: prices2 },
};
