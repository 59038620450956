import { Component } from '@/scripts/utils/component.js';
import { emitter } from '@/scripts/utils/emitter.js';
import { stadiumPrices } from '@/scripts/utils/stadiumPrices.js';

export const Stadium = Component('.js-stadium', (element) => {
    /** @type {HTMLElement[]} */
    const targets = [
        ...element.querySelectorAll('#SECTORS_OVERLAY [data-name]'),
    ];
    const modalStadiumContent = document.querySelector('.js-stadium-content');
    const labelTribuna = element.dataset.tribuna;
    const labelSettore = element.dataset.settore;

    const getTribunaSettore = (element_) => {
        const settore = element_.dataset.name.trim().split('-')[0];
        const tribuna = element_.parentElement.id.split('-')[0];
        return { tribuna, settore };
    };

    const checkDisabledSectors = () => {
        targets.forEach((element_) => {
            if (!element_.dataset.name) return;
            const tribunaSettore = getTribunaSettore(element_);
            if (
                stadiumPrices[tribunaSettore.tribuna] &&
                !stadiumPrices[tribunaSettore.tribuna][tribunaSettore.settore]
            ) {
                element_.classList.add('disabled');
            } else if (
                stadiumPrices[tribunaSettore.tribuna] &&
                stadiumPrices[tribunaSettore.tribuna][tribunaSettore.settore]
            ) {
                if (
                    stadiumPrices[tribunaSettore.tribuna][
                        tribunaSettore.settore
                    ].prices.filter((price) => !price.soldOut).length === 0
                ) {
                    element_.classList.add('sold-out');
                }
            }
        });
    };

    const withDot = (price) => price.toString().replace(',', '.');

    const getPriceHTML = (price) => {
        const priceWithDot = withDot(price.price);
        let priceHtml = `<div>${priceWithDot} €</div>`;

        if (price.discounted) {
            const discounted = `<span class="discounted">${withDot(
                price.price
            )} €</span>`;
            const originalPrice = `<span class="color-red">${withDot(
                price.discounted
            )} €</span>`;
            priceHtml = `<div>${discounted}${originalPrice}</div>`;
        }
        if (price.soldOut) {
            priceHtml = `<div class="sold-out">Sold out</div>`;
        }
        return priceHtml;
    };

    const appendRow = (price, priceFase2) => {
        if (price.price === null || price?.price?.length === 0) return;
        const priceRow = document.createElement('div');
        priceRow.classList.add('price-row');

        const productLabels = window.products_labels[price.product];
        let productSubTitle = '';
        if (productLabels.subtitle !== null) {
            productSubTitle = `<div class="product-subtitle">${productLabels.subtitle}</div>`;
        }
        const product = `<div>${productLabels.title}${productSubTitle}</div>`;

        // priceRow.innerHTML = `${product}${getPriceHTML(price)}${getPriceHTML(
        //     priceFase2
        // )}`;
        priceRow.innerHTML = `${product}${getPriceHTML(priceFase2)}`;
        modalStadiumContent.append(priceRow);
    };

    const appendHead = () => {
        const priceRowHead = document.createElement('div');
        priceRowHead.classList.add('price-row', 'price-row__head');
        priceRowHead.innerHTML = [
            `<div></div>`,
            // `<div>${element.dataset['fase-1']}</div>`,
            `<div>${element.dataset['fase-2']}</div>`,
        ].join('');
        modalStadiumContent.append(priceRowHead);
    };

    targets.forEach((element_) => {
        element_.addEventListener('click', () => {
            if (!element_.dataset.name) return;
            const tribunaSettore = getTribunaSettore(element_);
            modalStadiumContent.textContent = '';
            appendHead();
            stadiumPrices[tribunaSettore.tribuna][
                tribunaSettore.settore
            ].prices.forEach((price, index) => {
                appendRow(
                    price,
                    stadiumPrices[`${tribunaSettore.tribuna}_FASE_2`][
                        tribunaSettore.settore
                    ].prices[index]
                );
            });

            emitter.emit('modal:open', 'StadiumContent', {
                title: `${labelTribuna} ${
                    stadiumPrices[tribunaSettore.tribuna][
                        tribunaSettore.settore
                    ].label
                }`,
                subtitle: `${labelSettore} ${tribunaSettore.settore}`,
            });
        });
    });

    checkDisabledSectors();
});
