import { Component } from '@/scripts/utils/component.js';

export const Faq = Component('.js-faq', (element) => {
    /** @type {HTMLElement[]} */
    const headItems = [...element.querySelectorAll('.head-item')];
    /** @type {HTMLElement[]} */
    const questions = [...element.querySelectorAll('[data-faq-question]')];

    headItems.forEach((item) => {
        item.addEventListener('click', () => {
            element
                .querySelector('.head-item.selected')
                .classList.remove('selected');
            element
                .querySelector('.js-faq__group.show')
                .classList.remove('show');

            const targetGroup = item.dataset.faqGroup;

            item.classList.add('selected');
            element
                .querySelector(`[data-faq-list="${targetGroup}"]`)
                .classList.add('show');
        });
    });

    questions.forEach((question) => {
        question.addEventListener('click', () => {
            const targetQuestion = question.dataset.faqQuestion;
            const targetAnswer = element.querySelector(
                `[data-faq-answer="${targetQuestion}"]`
            );

            if (question.classList.contains('active')) {
                targetAnswer.style.height = '0';
                question.classList.remove('active');
                return;
            }

            questions.forEach((q) => q.classList.remove('active'));
            question.classList.add('active');

            [...element.querySelectorAll('.js-faq__answer')].forEach(
                (answer) => {
                    answer.style.height = '0';
                }
            );
            targetAnswer.style.height = `${
                targetAnswer
                    .querySelector('.js-answer__inner')
                    .getBoundingClientRect().height
            }px`;
        });
    });
});
