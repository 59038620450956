import { emitter } from '@/scripts/utils/emitter.js';
import { Component } from '@/scripts/utils/component.js';

export const Modal = Component('.js-modal', (modal) => {
    const close = modal.querySelector('.js-close-overlay');
    const modalTitle = modal.querySelector('.js-title');
    const modalSubtitle = modal.querySelector('.js-subtitle');
    const backdrop = document.querySelector('.js-modal-backdrop');
    /** @type {HTMLElement} */
    let element;

    const hideBackdrop = () => {
        backdrop.classList.remove('reveal');
    };

    const showBackdrop = () => {
        backdrop.style.display = 'block';

        setTimeout(() => {
            backdrop.classList.add('reveal');
        });
    };

    const openModal = () => {
        showBackdrop();
        element.classList.add('reveal');
    };

    const closeModal = () => {
        window.history.replaceState(null, null, ' ');
        modal.classList.remove('reveal');
        hideBackdrop();
    };

    backdrop.addEventListener('transitionend', () => {
        if (!backdrop.classList.contains('reveal')) {
            backdrop.style.display = 'none';
        }
    });

    backdrop.addEventListener('click', () => {
        closeModal();
    });

    close.addEventListener('click', () => {
        closeModal();
    });

    emitter.on('modal:close', () => {
        closeModal();
    });

    emitter.on('modal:open', (modalID, data) => {
        if (modal.id !== modalID) return;
        element = document.querySelector(`#${modalID}`);
        if (!element) {
            console.warn(`Modal with ID ${modalID} not found`);
            return;
        }
        if (data && data.title) {
            modalTitle.textContent = data.title;
        }
        if (data && data.subtitle) {
            modal.classList.add('with-subtitle');
            modalSubtitle.textContent = data.subtitle;
        } else {
            modalSubtitle.textContent = '';
            modal.classList.remove('with-subtitle');
        }
        window.location.hash = modalID.toLowerCase();
        openModal();
    });
});
