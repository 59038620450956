import { getCurrentLang } from '@/scripts/utils/currentLang.js';

export const loadJuveFooter = () => {
    $(() => {
        if (window.location.search.indexOf('appview=true') < 1) {
            $('#includedFooter').load(
                `https://www.juventus.com/${getCurrentLang()}/thirdparties/_libraries/_footer_v2`,
                () => {
                    window.d3_languages = [
                        {
                            id: 'en',
                            text: 'English',
                            url: 'https://abbonamenti.juventus.com/en',
                        },
                        {
                            id: 'it',
                            text: 'Italiano',
                            url: 'https://abbonamenti.juventus.com/it',
                        },
                    ];
                    window.footer_v2.setLanguage_v2(getCurrentLang());
                }
            );
        }
    });
};
