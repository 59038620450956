import { buildPrices, LABELS } from './stadiumUtils.js';

const prices = buildPrices(['1', '2', '3']);
const label = `${window.punti_cardinali[LABELS.ovest]} 1`;
export const OVEST_1 = {
    // 101: { label, prices },
    // 102: { label, prices },
    // 103: { label, prices },
    // 104: { label, prices },
    // 105: { label, prices },
    123: { label, prices: buildPrices(['', 795, '']) },
    // 124: { label, prices },
    // 125: { label, prices },
    // 126: { label, prices },
};

const prices2 = buildPrices(['1', '2', '3']);
export const OVEST_1_FASE_2 = {
    // 101: { label, prices: prices2 },
    // 102: { label, prices: prices2 },
    // 103: { label, prices: prices2 },
    // 104: { label, prices: prices2 },
    // 105: { label, prices: prices2 },
    123: { label, prices: buildPrices(['', 795, '']) },
    // 124: { label, prices: prices2 },
    // 125: { label, prices: prices2 },
    // 126: { label, prices: prices2 },
};
