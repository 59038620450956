import { emitter } from '@/scripts/utils/emitter.js';

export const initModals = () => {
    [...document.querySelectorAll('[data-modal]')].forEach((element_) => {
        element_.addEventListener('click', (event) => {
            event.preventDefault();
            emitter.emit('modal:open', element_.dataset.modal);
        });
    });
    document.addEventListener('keyup', (event) => {
        if (event.key === 'Escape') {
            emitter.emit('modal:close');
        }
    });
    window.addEventListener('hashchange', () => {
        if (window.location.hash === '') {
            emitter.emit('modal:close');
        }
    });
};
